<template>
<div>
    <dialog-message
        :dialog_message="dialog_message"
        :message="message"
    ></dialog-message>
    <v-navigation-drawer
        id="core-navigation-drawer"
        v-model="cartDrawer"
        color="white"
        right
        mobile-breakpoint="9999"
        app
        width="40%"
        v-bind="$attrs"
    >
    <div class="black--text text-h2 px-4 py-2">{{ $t('shopping.cart') }}</div>
    <v-divider/>
    <v-list
      dense
      nav
      class="mt-4 black--text"
    >
        <template v-if="shoppingItems.length>0">
            <template v-for="(item, index) in shoppingItems">
                <v-list-item :key="index">
                    <v-list-item-content>
                        <v-list-item-title
                            class="text-h3"
                            v-text="item.name"
                        />
                        <v-list-item-subtitle
                            class="text-h5"
                        >
                            {{ item.descriptions }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                            class="text-h5"
                        >
                           $ {{ item.price }}
                        </v-list-item-subtitle>
                        <v-list-item-action class="ma-0 justify-end">
                            <v-list-item-action-text v-if="item.id">
                                <v-btn
                                    depressed
                                    fab
                                    x-small
                                    class="ma-0"
                                    color="transparent"
                                    @click="removeItem(item)"
                                >
                                    <v-icon
                                        color="red lighten-1"
                                    >
                                        mdi-minus-circle
                                    </v-icon>
                                </v-btn>
                                <div class="text-h5 mx-2 d-inline-flex">{{ item.count }}</div>
                                <v-btn
                                    depressed
                                    fab
                                    x-small
                                    class="ma-0"
                                    color="transparent"
                                    @click="addItem(item)"
                                >
                                    <v-icon
                                        color="red lighten-1"
                                    >
                                        mdi-plus-circle
                                    </v-icon>
                                </v-btn>
                            </v-list-item-action-text>
                            <v-list-item-action-text v-else>
                                <v-btn
                                    depressed
                                    fab
                                    x-small
                                    class="ma-0"
                                    color="transparent"
                                    @click="removeItem(item)"
                                >
                                    <v-icon
                                        color="red lighten-1"
                                    >
                                        mdi-minus-circle
                                    </v-icon>
                                </v-btn>
                            </v-list-item-action-text>
                        </v-list-item-action>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <v-list-item class="justify-end">
                <v-list-item-action class="ma-0">
                    {{ $t('shopping.totalAmount') }} $ {{ totalAmount }}
                </v-list-item-action>
            </v-list-item>
            <v-divider/>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="text-h3">
                      {{ $t('invoice.title') }}
                    </v-list-item-title>
                    <v-select
                      hide-details
                      v-model="invoiceType"
                      :items="role===UserRole.CompanyAdmin||role===UserRole.CompanyUser?CompanyInvoiceItems:InvoiceItems"
                      item-value="value"
                      :label="$t('invoice.selectInvoiceType')"
                    >
                      <template slot="selection" slot-scope="data">
                        {{ $t(data.item.text) }}
                      </template>
                      <template slot="item" slot-scope="data">
                        {{ $t(data.item.text) }}
                      </template>
                    </v-select>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="invoiceType!=null">
                <v-list-item-content>
                    <template v-if="invoiceType===InvoiceType.Einvoice">
                      <v-radio-group v-model="EinvoiceGroup" class="mt-0" hide-details>
                        <v-radio
                          :label="$t('invoice.phone')"
                          :value="EinvoiceType.phone"
                        ></v-radio>
                        <v-radio
                          :label="$t('invoice.natureID')"
                          :value="EinvoiceType.nature"
                        ></v-radio>
                      </v-radio-group>
                      <v-list-item-subtitle
                        v-if="EinvoiceGroup!=null"
                      >
                        <v-text-field
                          v-model="barCode"
                          :label="$t('invoice.barCode')"
                        ></v-text-field>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                          v-if="EinvoiceGroup!=null"
                          class="text-h5"
                      >
                        <div class="mb-1">{{ $t('common.desc') }}：</div>
                        <div>{{ $t('invoice.barCodeDesc') }}</div>
                      </v-list-item-subtitle>
                      <template v-if="EinvoiceGroup===EinvoiceType.phone">
                        <v-img
                          dark
                          src="@/assets/images/phone-barCode.png"
                        />
                        <v-list-item-subtitle
                          class="text-h5 invoice-desc"
                        >
                          <div>{{ $t('invoice.phoneDesc') }}</div>
                        </v-list-item-subtitle>
                      </template>
                      <template v-if="EinvoiceGroup===EinvoiceType.nature">
                        <v-img
                          dark
                          src="@/assets/images/nature-barCode.png"
                        />
                        <v-list-item-subtitle
                          class="text-h5 invoice-desc"
                        >
                          <div>{{ $t('invoice.natureDesc') }}</div>
                        </v-list-item-subtitle>
                      </template>
                    </template>
                    <template v-if="invoiceType===InvoiceType.Donate">
                      <v-select
                        hide-details
                        v-model="donated"
                        :items="DonatedItems"
                        item-value="value"
                        :label="$t('donated.select')"
                      >
                        <template slot="selection" slot-scope="data">
                          {{ $t(data.item.text) }}-{{ data.item.value }}
                        </template>
                        <template slot="item" slot-scope="data">
                          {{ $t(data.item.text) }}-{{ data.item.value }}
                        </template>
                      </v-select>
                      <v-text-field
                        v-model="otherDonated"
                        v-if="donated=='other'"
                        :label="$t('donated.donateOther')"
                      ></v-text-field>
                      <v-list-item-subtitle
                        v-if="donated=='other'"
                        class="text-h5"
                      >
                        <div class="mb-1">{{ $t('common.desc') }}：</div>
                        <div>{{ $t('donated.donateDesc') }}</div>
                      </v-list-item-subtitle>
                    </template>
                    <template v-if="invoiceType===InvoiceType.PrintFlag">
                      <v-list-item-subtitle
                        class="text-h5 invoice-desc"
                      >
                        <div class="mb-1">{{ $t('common.desc') }}：</div>
                        <div>{{ $t('donated.printFlagDesc') }}</div>
                      </v-list-item-subtitle>
                    </template>
                </v-list-item-content>
            </v-list-item>
            <v-divider/>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-action class="ma-0">
                        <v-list-item-action-text>
                          <v-spacer />
                            <v-btn
                              class="mr-0 text-h5"
                              color="primary"
                              :disabled="loading"
                              @click="save"
                            >
                                <v-icon class="mr-1">mdi-cash-multiple</v-icon>{{ $t('shopping.buy') }}
                            </v-btn>
                        </v-list-item-action-text>
                    </v-list-item-action>
                </v-list-item-content>
            </v-list-item>
        </template>
        <template v-else>
            <v-list-item class="justify-center">
                <span class="text-h3 grey--text">{{ $t('shopping.empty') }}</span>
            </v-list-item>
        </template>
        <template v-if="debug">
          <form v-show="false" id="purchase_form" action="https://ccore.newebpay.com/MPG/mpg_gateway" method="POST">
            <input type="hidden" name="MerchantID" :value="purchaseData.MerchantID" id="id_MerchantID">
            <input type="hidden" name="TradeInfo" :value="purchaseData.TradeInfo" id="id_TradeInfo">
            <input type="hidden" name="TradeSha" :value="purchaseData.TradeSha" id="id_TradeSha">
            <input type="hidden" name="Version" :value="purchaseData.Version" id="id_Version">
          </form>
        </template>
        <template v-else>
          <form v-show="false" id="purchase_form" action="https://core.newebpay.com/MPG/mpg_gateway" method="POST">
            <input type="hidden" name="MerchantID" :value="purchaseData.MerchantID" id="id_MerchantID">
            <input type="hidden" name="TradeInfo" :value="purchaseData.TradeInfo" id="id_TradeInfo">
            <input type="hidden" name="TradeSha" :value="purchaseData.TradeSha" id="id_TradeSha">
            <input type="hidden" name="Version" :value="purchaseData.Version" id="id_Version">
          </form>
        </template>
    </v-list>
    </v-navigation-drawer>
</div>
</template>

<script>
  // Utilities
  import {
    mapState, 
    mapMutations,
  } from 'vuex'
  import { InvoiceType, InvoiceItems, CompanyInvoiceItems, DonatedItems, UserRole } from '@/definition'

  export default {
    name: 'ShoppingCartDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: (vm) => ({
      debug: process.env.VUE_APP_DEBUG=='false'?false:true,
      loading: false,
      overlay: false,
      cartDrawer: false,
      totalAmount: 0,
      dialog_message: false,
      message: {
        title: "",
        text: ""
      },
      purchaseData: {},
      InvoiceType: InvoiceType,
      InvoiceItems: InvoiceItems,
      CompanyInvoiceItems: CompanyInvoiceItems,
      DonatedItems: DonatedItems,
      UserRole: UserRole,
      invoiceType: null,
      EinvoiceGroup: null,
      EinvoiceType: {
        phone: 0,
        nature: 1,
      },
      barCode: '',
      donated: '',
      otherDonated: '',
      invoiceData: {},
    }),

    computed: {
      ...mapState(['token', 'shoppingItems', 'role']),
      shoppingCart: {
        get () {
          return this.$store.state.shoppingCart
        },
        set (val) {
          this.$store.commit('SET_SHOPPING_CART', val)
        },
      },
    },

    watch:{
        shoppingCart(){
            this.changeDisplay()
        },
        shoppingItems: {
            handler: function(val) {
                this.totalAmount = 0
                val.forEach(element => {
                    this.totalAmount += (element.price * element.count)
                });
            },
            immediate: true,
            deep: true,
        }
    },

    methods: {
        ...mapMutations({
            SET_SHOPPING_CART: "SET_SHOPPING_CART",
            addShoppingItems: "addShoppingItems",
            removeShoppingItems: "removeShoppingItems",
        }),
        changeDisplay(){
            this.overlay = !this.overlay
            this.cartDrawer = !this.cartDrawer
        },
        addItem(product){
            this.addShoppingItems(product)
        },
        removeItem(product){
            this.removeShoppingItems(product)
        },
        checkEinvoiceData(){
          let valid = false
          let result = false
          this.invoiceData = {
            invoiceType: this.invoiceType,
            EinvoiceType: this.EinvoiceGroup,
            barCode: this.barCode,
          }
          switch(this.EinvoiceGroup){
            case this.EinvoiceType.phone:
              let phone_pattern = /\/[A-Z0-9+-.]{7}$/
              result = phone_pattern.test(this.barCode)
              if(result == true){
                valid = true
              } else {
                alert(this.$i18n.t('invoice.invalidBarCode'))
              }
              break
            case this.EinvoiceType.nature:
              let nature_pattern = /^[A-Z]{2}[0-9]{14}$/
              result = nature_pattern.test(this.barCode)
              if(result == true){
                valid = true
              } else {
                alert(this.$i18n.t('invoice.invalidBarCode'))
              }
              break
          }
          return valid
        },
        checkDonateData(){
          let valid = false
          let donated_pattern = /^[0-9]{3,7}$/i
          let result = false
          this.invoiceData = {
            invoiceType: this.invoiceType,
          }
          if(this.donated === 'other'){
            result = donated_pattern.test(this.otherDonated)
            this.invoiceData['donated'] = this.otherDonated
          } else {
            result = donated_pattern.test(this.donated)
            this.invoiceData['donated'] = this.donated
          }
          if(result == true){
            valid = true
          } else {
            alert(this.$i18n.t('invoice.invalidDonated'))
          }
          return valid
        },
        checkInvoiceValid(){
          let valid = false
          switch(this.invoiceType){
            case InvoiceType.Einvoice:
              valid = this.checkEinvoiceData()
              break
            case InvoiceType.Donate:
              valid = this.checkDonateData()
              break
            case InvoiceType.PrintFlag:
              valid = true
              break
          }
          return valid
        },
        save() {
          let invoiceValid = this.checkInvoiceValid()
          if(!invoiceValid){
            return
          }
          // create hn account
          this.loading = true;
          const currentObj = this;
          // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
          const url = process.env.VUE_APP_SERVER_URL + "/backend/purchase/purchase/";
          let config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: "token " + this.token
            }
          };
          let data = {
            purchase_item : this.shoppingItems,
            total : this.totalAmount,
            invoiceData: this.invoiceData,
          };
          this.axios
            .post(url, data, config)
            .then(function(response) {
              currentObj.loading = false;
              // var newWindow = window.open()
              // newWindow.document.write(response.data)
              currentObj.purchaseData = response.data.purchase_data
              currentObj.$nextTick(()=>{
                let form = document.getElementById('purchase_form')
                form.submit()
              })
            })
            .catch(function(error) {
              console.log(error);
              console.log(error.response);
              currentObj.loading = false;
              currentObj.message.title = currentObj.$t("save-fail");
              currentObj.message.text = JSON.stringify(error.response.data);
              currentObj.dialog_message = !currentObj.dialog_message;
            });
        },
    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  .v-navigation-drawer
    background-color: rgba(0,0,0,0.1)!important

  #core-navigation-drawer
    min-width: 260px
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important
      .v-list-item__avatar
        margin: 0
      .v-list-item__title
        line-height: 1.4em
      .v-chip
        &.avatar-icon
          position: absolute
          top: 130px
          left: 90px

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
  .invoice-desc
    white-space: break-spaces
    line-height: 1.1rem !important
</style>
